@font-face {
  font-family: "PxGroteskRegular";
  src: url("/assets/fonts/PxGroteskRegular.ttf") format("truetype");
}
@font-face {
  font-family: "PxGroteskLight";
  src: url("/assets/fonts/PxGroteskLight.ttf") format("truetype");
}

* {
  font-weight: 500;
  font-family: PxGroteskRegular;
}
h1,
h2 {
  font-size: 2.8rem;
}
h3 {
  font-size: 1.5rem;
}
body {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  width: 100%;
  height: auto;
}

.logo {
  width: 150px;
  margin: 20px 0 20px 0;
}

.container {
  width: 80%;
  margin: auto;
}

strong {
  font-weight: 600;
}

.page-section {
  display: none;
  &.visible {
    display: block;
  }
}

.grid-simple {
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%;
  grid-row-gap: 15px;

  .download-button {
    grid-column-start: 5;
  }
}

.section-row {
  margin: 15px 0 15px 0;
}

.border {
  padding: 15px;
  border: 1px solid #ccc;
}
.number {
  font-size: 1.2rem;
}
#title {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  h1 {
    margin: 0 10px 0 0;
  }

  span {
    font-size: 20px;
  }
}

.nav-buttons {
  margin: 20px 0 50px 0;
  display: flex;
  justify-content: space-between;

  .nav-btn {
    display: flex;
    justify-content: space-evenly;
  }

  .nav-btn-center {
    width: 50px;
  }
}

.check-svg {
  display: none;
  position: absolute;
  width: 35px;
  top: -10px;
  right: -10px;
  z-index: 10;
}

.outline-btn {
  background: none;
  padding: 10px 5px;
  border: 1px solid #444;
  border-radius: 2px;
  font-size: 1.1rem;
  width: 100px;
  transition: color 0.5s;
  transition: background 0.5s;

  &:hover {
    transition: color 0.5s;
    transition: background 0.5s;
    cursor: pointer;
    background: #444;
    color: #ddd;
  }
}

/* download pdf button */

.download-pdf {
  position: absolute;
  left: 65%;
  width: 140px;
  margin-top: 20px;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

// SCHEME PAGE

#page-scheme-selector {
  h2 {
    margin-top: 10px;
  }
}

#scheme-container {
  li {
    border: 1px solid #111;
  }

  button {
    background: none;
    border: 0;
    &:hover {
      cursor: pointer;
    }

    h3 {
      text-align: left;
    }
  }
}

// LAYOUT PAGE
.reset-button {
  position: relative;
}
#reset {
  right: 30px;
  top: 30px;
  position: absolute;
}

#tiles-layout {
  h2 {
    padding-top: 10px;
    font-size: 55px;
    line-height: 45px;
    margin: 0;
  }
  h3 {
    font-size: 30px;
    margin: 0;
  }
  .color-selectors {
    margin: 35px 0 20px;
    display: flex;

    .color {
      margin-right: 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      .color-fill {
        margin-left: 8px;
        border: 1px solid #888;
        width: 35px;
        height: 35px;
        cursor: pointer;
      }
    }

    .color-fuga {
      margin-left: auto;
    }
  }
  .order-sizes {
    margin: 0 0 10px;
    span {
      margin-right: 15px;
    }
  }
}
#tiles {
  border: 0.5px solid #ccc;
}
#svg-pivot {
  display: none;
}

#layout-title {
  margin: 10px 0 0 0;
}

#layout-title-container {
  width: calc(100% - 25px);
  display: flex;
  flex-direction: column;
}

#layout-description {
  margin: 5px 0 5px;
  font-size: 18px;
  color: #333;
}

#layout-container {
  li {
    display: flex;
    .number {
      width: 25px;
    }
    .grid-simple {
      width: calc(100% - 25px);
    }
  }

  label {
    position: relative;
    input:checked ~ .check-svg {
      display: inherit;
    }
    input {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

#layout-scheme-info {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  font-size: 1.2rem;

  .row {
    margin-right: 0;
    .input {
      margin-right: 0 !important;
      input {
        width: 60px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.row {
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h4 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-family: PxGroteskLight;
  }

  .input {
    margin-right: 30px;
    display: flex;
    align-items: flex-end;
    input {
      font-size: 1.2rem;
      border: 0;
      border-bottom: 1.2px solid #aaa;
    }
  }
}

// COLOR MODALS
#color-modals {
  .color-modal {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);

    &.visible {
      display: flex;
      justify-content: center;
    }

    .border {
      margin-top: 20px;
      background: #fff;
      width: 800px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      h3 {
        margin-top: 0;
      }

      .color-grid {
        display: grid;
        grid-gap: 10px;
        height: 230px;
        .color-column {
          height: 100%;
          grid-row: 1 / 2;
          position: relative;

          &:hover {
            cursor: pointer;
          }

          input {
            display: none;
          }

          input:checked ~ .color-fill {
            box-shadow: inset 0 0 0 10px lightgreen;
          }

          .color-fill {
            width: 100%;
            height: 100%;
          }
        }
      }

      button {
        margin: 20px 0 10px 0;
        align-self: center;
      }

      &.color2-row {
        display: none;
        &.visible {
          display: inherit;
        }
      }

      &.color3-row {
        display: none;
        &.visible {
          display: inherit;
        }
      }
    }
  }
}

// ORDER SECTION
#order-section {
  margin-top: 30px;
  h2 {
    margin: 0;
    font-size: 2.5rem;
  }
  h3 {
    margin: 0;
    font-size: 1.8rem;
    font-family: PxGroteskLight;
    font-weight: 500;
  }
  h5 {
    margin: 15px 0 5px 0;
    font-size: 1.8rem;
    font-family: PxGroteskLight;
    font-weight: 500;
  }

  .order-content {
    display: grid;
    grid-template-areas:
      "schemeImage first"
      "schemeImage second"
      "orderImage orderImage";
    .schemeImage {
      grid-area: schemeImage;
      max-width: 200px;
    }
    .orderImage {
      grid-area: orderImage;
    }

    .main-table {
      width: 100%;
      .second-table {
        border: 0;
        th,
        td {
          border: 0;
        }
        tr {
          border-bottom: 1px solid black;
          border-top: 1px solid black;
        }
      }
    }
  }

  #order-review {
    h3 {
      margin: 20px 0;
    }
    .main-table {
      width: 100%;
    }
  }
}

.form-input {
  border: none;
  width: 100%;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}

#customer-information-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  input {
    width: 18rem;
  }
}

@media (max-width: 1400px) {
  .download-pdf {
    left: 63%;
  }
}
@media (max-width: 1199px) {
  .grid-simple {
    grid-template-columns: 23% 23% 23% 23%;

    .download-button {
      grid-column-start: 4;
    }
  }

  .download-pdf {
    left: 60%;
  }
}
@media (max-width: 1024px) {
  .download-pdf {
    left: 58%;
  }
}
@media (max-width: 999px) {
  .grid-simple {
    grid-template-columns: 32% 32% 32%;

    .download-button {
      grid-column-start: 3;
    }
  }

  .download-pdf {
    left: 56%;
  }
}
@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .grid-simple {
    grid-template-columns: 48% 48%;

    .download-button {
      grid-column-start: 1;
    }
  }

  #layout-scheme-info {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .color-selectors {
    flex-wrap: wrap;

    .color {
      margin-top: 10px;
    }
  }

  .order-content {
    display: grid;
    grid-template-areas:
      "schemeImage"
      "first"
      "second" !important;

    .schemeImage {
      margin-bottom: 5px;
    }
  }
}
@media (max-width: 428px) {
  .container {
    width: 95%;
  }

  #title {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .color-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .color-column {
      grid-row: unset !important;
    }
  }

  .download-pdf {
    position: static;
    display: block;
    margin: 15px auto;
  }
}
